import React, {useEffect, useState} from "react"
import Layout from "../components/Layout/Layout"
import {StaticImage} from "gatsby-plugin-image";
export default function Teamviewer() {

    const [prohlizec, setProhlizec] = useState('chrome');

    return (
        <Layout>
            <div className="headerBlue">
                <div className="container">
                    <h1 className="pt-5 pb-5 d-none d-md-block"></h1>
                    <h1 className="pt-5 pb-5 text-center d-block d-md-none"></h1>
                </div>
            </div>
            <div className="container relative mt-5">
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <div class="ms-auto me-auto" style={{maxWidth: 300 + 'px'}}>
                            <StaticImage imgClassName={"navod"} src="../assets/custom-style/images/navod/tw2.svg"
                                         alt="navod"/>
                        </div>
                        <br/>
                        <br/>
                        <div className="row">
                            <div className="col-sm-6 mb-3 mb-sm-0">
                                <a className="btn btn-lg btn-primary"
                                   href="https://www.digioffice.cz/download/TeamViewerQS_cs.exe">Stáhnout
                                    TeamViewer<br/> <strong>pro Windows</strong></a> <br/>
                            </div>
                            <div className="col-sm-6">
                                <a className="btn btn-lg btn-primary" href="https://www.digioffice.cz/download/TeamViewer.dmg">Stáhnout
                                    TeamViewer<br/> <strong>pro Mac</strong></a>
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <p className={"fs-3"}>
                            Přinášíme Vám také podrobný návod pro stažení a spuštění programu TeamViewer, který
                            je <strong>určen pro okamžitou vzdálenou
                            pomoc smluvním zákazníkům</strong>.
                        </p>
                    </div>
                </div>
            </div>
            <div class="container-md my-5 text-center">
                <h3>Vyberte si prosím vaši verzi prohlížeče:</h3>

                <div className=" text-center">

                    <button id="browser-chrome" className="btn btn-lg btn-primary mb-2 me-1"
                            style={{minWidth: 200 + 'px'}}
                            onClick={() => {
                                setProhlizec('chrome');
                                console.log(prohlizec)
                            }}>Google
                        Chrome
                    </button>

                    <br className={"d-md-none"}/>

                    <button id="browser-firefox" className="btn btn-lg btn-primary mb-2 me-1"
                            style={{minWidth: 200 + 'px'}}
                            onClick={() => {
                                setProhlizec('firefox')
                            }}>Mozilla Firefox
                    </button>
                    <br className={"d-md-none"}/>
                    <button id="browser-ie" href="#ie"
                            style={{minWidth: 200 + 'px'}}
                            className="btn btn-lg btn-primary mb-2 me-1"
                            onClick={() => {
                                setProhlizec('ie')
                            }}>Internet Explorer
                    </button>

                </div>

            </div>
            <div class="container-md">

                {prohlizec === 'chrome' ?
                    <div id="chrome">
                        <div className="download row">
                            <h2 className={"text-center"}>Návod pro Google Chrome</h2>
                            <div id="prvni-krok" className="col-12 text-center">
                                <div className="text-start">
                                    <h2>1. krok</h2>
                                </div>
                                <h3>Stáhněte si program <a
                                    href="https://www.digioffice.cz/download/TeamViewerQS_cs.exe">TeamViewer</a>
                                </h3>
                                <h3>
                                    Ve spodní části stránky se Vám zobrazila takováto lišta:
                                </h3>
                                <StaticImage imgClassName={"navod"}
                                             src="../assets/custom-style/images/navod/chrome/stahovani-zapocalo.png"
                                             alt="navod"/>
                                <h3>Na ní se zobrazuje průběh stahování.</h3>
                                <hr/>
                                {/*<a href="#druhy-krok" className="btn btn-outline-primary">Přejít na další*/}
                                {/*    krok <i*/}
                                {/*        className="fa fa-arrow-down"></i></a>*/}
                            </div>
                            <div id="druhy-krok" className="druhy-krok text-center">
                                <div className="nadpis text-start">
                                    <h2>2. krok</h2>
                                </div>
                                <div className="bg-section">
                                    <div className="container">
                                        <h3>Po dokončení stahování ikonka souboru na liště zabliká a změní se.
                                        </h3>
                                        <StaticImage imgClassName={"navod"}
                                                     src="../assets/custom-style/images/navod/chrome/stahovani-dokonceno.png"
                                                     alt="navod"/>
                                        <h3>Soubor spustíte kliknutím na tuto ikonku. </h3>
                                        <hr/>
                                        {/*<a href="#treti-krok" className="btn btn-outline-primary text-center">Přejít na*/}
                                        {/*    další krok <i*/}
                                        {/*        className="fa fa-arrow-down"></i></a>*/}

                                    </div>
                                </div>
                            </div>
                            <div id="treti-krok" className="treti-krok text-start">
                                <div className="nadpis">
                                    <h2>3. krok</h2>
                                </div>
                                <div className="bg-section">
                                    <div className="container text-center">
                                        <h3>Počítač si vyžádá potvzení spustění programu. </h3><h3>
                                        To provedete kliknutím na tlačítko "Spustit" v zobrazeném okně.
                                    </h3>
                                        <StaticImage imgClassName={"navod"}
                                                     src="../assets/custom-style/images/navod/chcete-spustit-soubor.png"
                                                     alt="navod"/>
                                        <hr/>
                                        {/*<a href="#ctvrty-krok" className="btn btn-outline-primary text-center">Přejít na*/}
                                        {/*    další krok <i*/}
                                        {/*        className="fa fa-arrow-down"></i></a>*/}
                                    </div>
                                </div>
                            </div>

                            <div id="ctvrty-krok" className="posledni-krok">
                                <div className="nadpis">
                                    <h2>4. krok</h2>
                                </div>
                                <div className="bg-section">
                                    <div className="container text-center">
                                        <h3>Nadiktujte operátorovi ID a jednorázové heslo.
                                        </h3>
                                        <StaticImage imgClassName={"navod"}
                                                     src="../assets/custom-style/images/navod/teamviewer.png"
                                                     alt="navod"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    : null}
                {prohlizec === 'firefox' ?

                    <div id="firefox" className="colapse">
                        <div className="download">
                            <h2 className={"text-center"}>Návod pro Mozilla Firefox</h2>
                            <div id="prvni-krok" className="prvni-krok text-center">
                                <div className="nadpis text-start">
                                    <h2>1. krok</h2>
                                </div>
                                <div className="bg-section">
                                    <div className="container">
                                        <h3>Stáhněte si program <a
                                            href="https://www.digioffice.cz/download/TeamViewerQS_cs.exe">TeamViewer</a>
                                        </h3>

                                        <h3>Zobrazí se Vám takovéto okno:
                                        </h3>
                                        <StaticImage imgClassName={"navod"}
                                                     src="../assets/custom-style/images/navod/firefox/otevirani-souboru.png"
                                                     alt="navod"/>
                                        <h3>Stažení souboru potvrdíte kliknutím na tlačítko "Uložit soubor". </h3>
                                        <hr/>
                                        {/*<a href="#druhy-krok-f" className="btn btn-outline-primary text-center">Přejít*/}
                                        {/*    na další krok <i*/}
                                        {/*        className="fa fa-arrow-down"></i></a>*/}

                                    </div>
                                </div>
                            </div>
                            <div id="druhy-krok-f" className="druhy-krok text-center">
                                <div className="nadpis text-start">
                                    <h2>2. krok</h2>
                                </div>
                                <div className="bg-section">
                                    <div className="container">
                                        <h3>V pravém horním rohu je vidět průběh stahování. </h3>
                                        <StaticImage imgClassName={"navod"}
                                                     src="../assets/custom-style/images/navod/firefox/stahovani-zapocalo.png"
                                                     alt="navod"/>
                                        {/*<img src="{$basePath}/images/navod/firefox/stahovani-zapocalo.png" className="navod"/>*/}
                                        <h3>Po dokončení stahování ikonka zabliká a změní se v modrou šipku. </h3>
                                        <StaticImage imgClassName={"navod"}
                                                     src="../assets/custom-style/images/navod/firefox/stahovani-dokonceno.png"
                                                     alt="navod"/>
                                        <hr/>
                                        {/*<a href="#treti-krok-f" className="btn btn-outline-primary text-center">Přejít*/}
                                        {/*    na další krok <i*/}
                                        {/*        className="fa fa-arrow-down"></i></a>*/}

                                    </div>
                                </div>
                            </div>
                            <div id="treti-krok-f" className="treti-krok text-center">
                                <div className="nadpis text-start">
                                    <h2>3. krok</h2>
                                </div>
                                <div className="bg-section">
                                    <div className="container text-center">
                                        <h3>Program spustíte kliknutím na ikonku šipky a následně na položku
                                            znázorňující
                                            stažený program.
                                        </h3>
                                        <StaticImage imgClassName={"navod"}
                                                     src="../assets/custom-style/images/navod/firefox/spusteni.png"
                                                     alt="navod"/>
                                        <hr/>
                                        {/*<a href="#ctvrty-krok-f" className="btn btn-outline-primary text-center">Přejít*/}
                                        {/*    na další krok <i*/}
                                        {/*        className="fa fa-arrow-down"></i></a>*/}
                                    </div>
                                </div>
                            </div>
                            <div id="ctvrty-krok-f" className="druhy-krok">
                                <div className="nadpis">
                                    <h2>4. krok</h2>
                                </div>
                                <div className="bg-section">
                                    <div className="container text-center">
                                        <h3>Počítač si vyžádá potvzení spustění programu.
                                        </h3>
                                        <StaticImage imgClassName={"navod"}
                                                     src="../assets/custom-style/images/navod/chcete-spustit-soubor.png"
                                                     alt="navod"/>

                                        <hr/>
                                        {/*<a href="#paty-krok-f" className="btn btn-outline-primary text-center">Přejít na*/}
                                        {/*    další krok <i*/}
                                        {/*        className="fa fa-arrow-down"></i></a>*/}
                                    </div>
                                </div>
                            </div>

                            <div id="paty-krok-f" className="posledni-krok">
                                <div className="nadpis">
                                    <h2>5. krok</h2>
                                </div>
                                <div className="bg-section">
                                    <div className="container text-center">
                                        <h3>Nadiktujte operátorovi ID a jednorázové heslo.
                                        </h3>
                                        <StaticImage imgClassName={"navod"}
                                                     src="../assets/custom-style/images/navod/teamviewer.png"
                                                     alt="navod"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    : null}
                {prohlizec === 'ie' ?

                    <div id="ie" className="colapse">
                        <div className="download">
                            <h2 className={"text-center"}>Návod pro Internet Explorer</h2>
                            <div id="prvni-krok" className="prvni-krok text-center">
                                <div className="nadpis text-start">
                                    <h2>1. krok</h2>
                                </div>
                                <div className="bg-section">
                                    <div className="container">
                                        <h3>Stáhněte si program <a
                                            href="https://www.digioffice.cz/download/TeamViewerQS_cs.exe">TeamViewer</a>
                                        </h3>

                                        <h3>Ve spodní části stránky se Vám zobrazila takováto lišta:

                                        </h3>
                                        <StaticImage imgClassName={"navod"}
                                                     src="../assets/custom-style/images/navod/ie/spustit-nebo-ulozit.png"
                                                     alt="navod"/>
                                        <h3>Stažení souboru potvrdíte kliknutím na tlačítko "Spustit". </h3>
                                        <hr/>
                                        {/*<a href="#druhy-krok-ie" className="btn btn-outline-primary text-center">Přejít*/}
                                        {/*    na další krok <i*/}
                                        {/*        className="fa fa-arrow-down"></i></a>*/}

                                    </div>
                                </div>
                            </div>
                            <div id="druhy-krok-ie" className="druhy-krok text-center">
                                <div className="nadpis text-start">
                                    <h2>2. krok</h2>
                                </div>
                                <div className="bg-section">
                                    <div className="container text-center">
                                        <h3>Na liště se nyní zobrazuje průběh stahování s následnou kontrolou
                                            zabezpečení. </h3>
                                        <StaticImage imgClassName={"navod"}
                                                     src="../assets/custom-style/images/navod/ie/stahovani-zapocalo.png"
                                                     alt="navod"/>
                                        <h3>Po dokončení stahování se soubor sám spustí.
                                        </h3>
                                        <hr/>
                                        {/*<a href="#treti-krok-ie" className="btn btn-outline-primary text-center">Přejít*/}
                                        {/*    na další krok <i*/}
                                        {/*        className="fa fa-arrow-down"></i></a>*/}

                                    </div>
                                </div>
                            </div>
                            <div id="treti-krok-ie" className="treti-krok">
                                <div className="nadpis">
                                    <h2>3. krok</h2>
                                </div>
                                <div className="bg-section">
                                    <div className="container text-center">
                                        <h3>Počítač si vyžádá potvzení spustění programu. </h3><h3>
                                        To provedete kliknutím na tlačítko "Spustit" v zobrazeném okně.
                                    </h3>
                                        <StaticImage imgClassName={"navod"}
                                                     src="../assets/custom-style/images/navod/chcete-spustit-soubor.png"
                                                     alt="navod"/>

                                        <hr/>
                                        {/*<a href="#ctvrty-krok-ie" className="btn btn-outline-primary text-center">Přejít*/}
                                        {/*    na další krok <i*/}
                                        {/*        className="fa fa-arrow-down"></i></a>*/}
                                    </div>
                                </div>
                            </div>

                            <div id="ctvrty-krok-ie" className="posledni-krok2">
                                <div className="nadpis">
                                    <h2>4. krok</h2>
                                </div>
                                <div className="bg-section">
                                    <div className="container text-center">
                                        <h3>Nadiktujte operátorovi ID a jednorázové heslo.
                                        </h3>
                                        <StaticImage imgClassName={"navod"}
                                                     src="../assets/custom-style/images/navod/teamviewer.png"
                                                     alt="navod"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    : null}


            </div>
        </Layout>
    )
        ;
}